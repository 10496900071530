.text-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pb-15 {
    padding-bottom: 15px;
}

.bg-f9 {
    background-color: #f9f9f9;
    border-radius: 14px !important;
    font-family: "Lexend" !important;
    font-style: normal !important;
   

    .ant-card-body {
        padding: 15px; 
    }


    .ant-divider-horizontal {
        border: 1px solid rgb(199, 191, 191);
        margin-bottom: 5px !important;
        margin-top: 5px !important;
    }

    
    .order-title {
        color: black;
        margin: 0 !important;
        font-weight: bold;
    }

    .order-description {
        color: #616161;
        margin: 0 !important;
    }

    .order-price {
        color: #e70736;
    }

    .order-sub-description {
        color: black;
        margin-top: 5px;
    }

    .pink-text {
        color: #ea082e;
    }

}

.bg-main{
        background-color: rgba(249, 249, 249, 1)  !important;
        
        .ant-card-body{
            padding: 15px !important;

            .ant-divider-horizontal {
                border: 1px solid #000 !important;
                margin-bottom: 5px!important;
                margin-top: 0px!important;
            }
        }

        .order-title {
            color: black;
            font-weight: bold;
        }
    
        .order-description {
            color: #616161;
        }
    
        .order-price {
            color: #e70736;
        }
    
        .order-sub-description {
            color: black;
            margin-top: 5px;
        }
    
  }