@import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Hubballi&family=Nunito:wght@200&family=Roboto:ital,wght@1,100;1,500&display=swap");

body {
  margin: 0 !important;
  padding: 0 !important;

  overflow: hidden;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-zero-width.sidebar {
  flex: 0 0 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
  width: 0 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #2b3344 !important;
  border-radius: 10px !important;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  padding: 10px !important;
}

.active {
  font-weight: bold;
}

.text-line::after {
  content: "";
  display: block;
  width: 4%;
  padding-top: 3px;
  border-bottom: 4px solid #ea0830;
}

.ant-btn,
.ant-btn-default {
  background: #e70736;
  border: 1px solid #e70736;
  color: white;
  min-width: 120px;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn-default:hover,
.ant-btn-default:focus {
  background: #e70736 !important;
  border-color: #e70736 !important;
  color: white !important;
  min-width: 120px;
}

.ant-input-number-input {
  // border-color: #727272 !important;
  border: 1px solid #727272 !important;
  border-radius: 0 !important;
}

.ant-input,
.ant-picker,
.ant-input-password,
.ant-select,
.ant-input-affix-wrapper {
  border: 1px solid #727272;
  border-radius: 0px !important;
}

.ant-input:hover,
.ant-input:focus,
.ant-picker:hover,
.ant-input-number-input,
.ant-picker:focus,
.ant-picker-focused:focus,
.ant-picker-focused:focus,
.ant-input-password:hover,
.ant-input-password:focus,
.ant-select:hover,
.ant-select:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: #727272 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.ant-select-selector {
  border-color: transparent !important;
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: 1px solid transparent !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e70736 !important;
  border-color: #e70736 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #e70736 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #e70736 !important;
}

.ant-switch-checked {
  background-color: #ff777f !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-button-white {
  background-color: transparent !important;
  border: 1px solid black !important;
  color: black;
  width: 150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-button {
  width: 150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-list {
  iframe {
    width: 100%;
    height: 100vh;
  }

  .ant-list-item-meta {
    align-items: center !important;
  }
}

//* all label remove padding *//
.ant-form-item-label {
  padding: 0px !important;
}

.custom-button {
  background: #e70736;
  border: 1px solid #e70736;
  color: white;
  min-width: fit-content !important;
}

.custom-button:hover,
.custom-button:focus {
  background: #e70736 !important;
  border-color: #e70736 !important;
  color: white !important;
}

.spacer {
  margin-top: 15px;
}

#overflow-menu {
  max-height: calc(100vh - 370px);
  overflow: auto;
}

/* Custom CSS scrollbar with 1px border */
#overflow-menu::-webkit-scrollbar {
  width: 10px;
  /* set the width of the scrollbar */
}

#overflow-menu::-webkit-scrollbar-track {
  background-color: transparent;
  /* set the background color of the scrollbar track */
}

#overflow-menu::-webkit-scrollbar-thumb {
  background-color: #e70736;
  /* set the background color of the scrollbar thumb */
  border-radius: 5px;
}

#overflow-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* set the background color of the scrollbar thumb on hover */
}
